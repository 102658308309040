<template>
    <v-row justify="center" align-content-center>
        <v-col cols="12" md="6" lg="4">
            <v-card class="elevation-5 mx-2" dark color="secondary">
                <v-card-text class="">
                    <p class="text-center white--text display-1 mb-5 shadowed">Account Login</p>
                    <v-alert class="mt-3 mb-5 mx-3 shadowed font-weight-bold" dense type="info" v-if="loggedOut">
                        <span class="white--text">You have been logged-out!</span>
                    </v-alert>
                    <v-alert class="mt-3 mb-5 mx-3 shadowed font-weight-bold" dense type="info" v-if="closed">
                        <span class="white--text">Your account has been closed.</span>
                    </v-alert>
                    <v-alert class="mt-2 mx-3" dense type="error" v-if="errors">
                        {{ errors }}
                    </v-alert>
                    <v-row dense class="mt-3 mx-3">
                        <v-col cols="12">
                            <v-text-field
                                :autofocus="!email.length && !$vuetify.breakpoint.smAndDown"
                                ref="email"
                                v-model="email"
                                outlined
                                prepend-inner-icon="fa fa-envelope"
                                label="Your Email Address"
                                type="email"
                                name="email"
                                class="hide-empty-msgs"
                                :success="validEmail"
                                placeholder="Ex: your.name@gmail.com"
                                :rules="[rules.required, rules.email]"
                                validate-on-blur
                                autocomplete
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                :autofocus="email.length > 1"
                                v-model="password"
                                prepend-inner-icon="fa fa-lock"
                                outlined
                                name="password"
                                type="password"
                                class="hide-empty-msgs"
                                label="Your Password"
                                :rules="[rules.required, rules.minlength]"
                                validate-on-blur
                                v-on:keyup.enter="login"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <p class="text-center mb-0">
                        <v-btn large rounded :loading="loggingIn" :disabled="!canLogin" @click.native="login" class="mt-3 mb-0 px-15" color="primary">Login</v-btn>
                    </p>
                    <v-divider class="mt-4 mb-3" />
                    <p class="text-center body-2 pb-0 mb-0">
                        <router-link class="primary--text action-item" to="/auth/forgot">Forgot your password?</router-link> 
                        <span class="px-2">&bull;</span>
                        <router-link class="primary--text action-item" to="/signup">Don't have an account?</router-link> 
                    </p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'AuthLogin',
    computed: {
        ...mapState(['user', 'existingEmail']),
        canLogin(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.email) && this.password && this.password.length > 5;
        },
        validEmail(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.email);
        }
    },
    data: () => ({
        loggingIn: false,
        closed: false,
        email: '',
        loggedOut: false,
        password: '',
        errors: null,
        ready: false,
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        }
    }),
    methods: {
        ...mapMutations(['setUser', 'setExistingEmail']),
        initData(){
            if(this.user && this.user.uuid){
                this.$router.push('/app');
            }
            if(this.existingEmail){
                this.email = this.existingEmail;
            }
            if(location.href.indexOf('logged-out') !== -1){
                this.$appFn.trackEvent({action: 'Logout', label: 'User logged out.'});
                this.loggedOut = true;
            }
            if(location.href.indexOf('closed') !== -1){
                this.closed = true;
            }
        },
        login(){ 
            this.loggingIn = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/login', 
                method: 'post',
                data: {
                    email: this.email,
                    password: this.password
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$appFn.trackEvent({action: 'login'});
                    this.$eventHub.$emit('loggingin');
                    this.setExistingEmail(res.data.email);
                    this.setUser(res.data);
                    location.href = '/app';
                }else if(res.status == 409){
                    // prompt to connect social account
                }else{
                    this.$appFn.trackEvent({ action: 'user_error', category: 'UserErrors', options: { action_name: 'Login: Invalid email or password' }});
                    this.errors = "Invalid email or password.";
                }
            }).catch(()=>{
                this.$appFn.trackEvent({ action: 'user_error', category: 'UserErrors', options: { action_name: 'Login: Invalid email or password' }});
                this.errors = "Invalid email or password.";
            }).finally(()=>{
                this.loggingIn = false;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>